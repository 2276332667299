import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import { title } from '@core/utils/filter'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useProductsList() {
  // Use toast
  const toast = useToast()

  const refProductsListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'product_id', label: 'Product ID', sortable: true },
    { key: 'productPic', label: 'Pic' },
    { key: 'productName', label: 'Name' },
    { key: 'userDisplayName', label: 'Owner', sortable: true },
    { key: 'productIndustryUI', label: 'Industry', sortable: true, formatter: (value, key, item) => {
      return value ? value : '-' }},
    { key: 'statusUI', label: 'Status', sortable: true },
    { key: 'updated_at', label: 'Updated at', sortable: true , formatter: (value, key, item) => {
      return value ? new Date(value).toLocaleString(): '-' }},
    { key: 'actions' },
  ]
  const productsList = ref(null)
  const perPage = ref(10)
  const totalProducts = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('product_id')
  const isSortDirDesc = ref(false)

  const dataMeta = computed(() => {
    const localItemsCount = refProductsListTable.value ? refProductsListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalProducts.value,
    }
  })

  // const refetchData = () => {
  //   refProductsListTable.value.refresh()
  // }

  watch([currentPage, perPage, searchQuery], () => {
    // refetchData()
  })

  //table items provider
  const fetchProducts = (ctx, callback) => {
    store
      .dispatch('app-user/fetchProducts')
      .then(response => {
        console.log(response.data)
        const { products } = response.data
        const modifiedProducts = products.map(products => ({
          ...products, 
          productName: products?.content[0].name,
          userDisplayName: products?.owner?.short_name + '' + products?.owner?.family_name,
          productIndustryUI: products?.industry_list?.name,
          statusUI: (products.status==1)?'Active':'Inactive',
        }))
        productsList.value = modifiedProducts
        totalProducts.value = products.length
        // callback(users)
      })
      .catch((error) => {
        console.log(error)
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching users list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveStatusVariant = status => {
    if (status === 'pending') return 'warning'
    if (status === 'active') return 'success'
    if (status === 'inactive') return 'secondary'
    return 'primary'
  }

  return {
    fetchProducts,
    productsList,
    tableColumns,
    perPage,
    currentPage,
    totalProducts,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refProductsListTable,

    resolveStatusVariant,
    // refetchData,

  }
}
